import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import React, { Fragment, useState, useRef } from 'react'
import 'primereact/resources/themes/lara-light-cyan/theme.css'
import { Toast } from 'primereact/toast'
import { useParams } from 'react-router-dom'
import '../../../css/AddClient.css'
import { Link, useNavigate } from 'react-router-dom'
import IndustryList from '../../utils/Constants/IndustryList'
import { API_URL } from '../../utils/Constants/URLS'
import IndianStates from '../../utils/Constants/IndianStateList'
import Select from 'react-select'
const AddClient = props => {
  const { assignToLead } = props
  const toast = useRef(null)
  const token = localStorage.getItem('token')
  const { id } = useParams()
  let countAddress = 0
  let countContactPerson = 0
  const navigate = useNavigate()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [industry, setIndustry] = useState('')
  let [phoneNumberOptional, setPhoneNumberOptional] = useState(null)
  const [organizationType, setOrganizationType] = useState('')
  const [isGSTRegistered, setIsGSTRegistered] = useState('true')
  const [panNumber, setPanNumber] = useState('')
  const [gstNumber, setGstNumber] = useState(null)
  const [address, setAddress] = useState([
    {
      addressNickName: '',
      addressLine1: '',
      addressLine2: null,
      city: '',
      state: '',
      country: 'India',
      pincode: ''
    }
  ])
  const [contactPersons, setContactPersons] = useState([
    {
      name: '',
      email: '',
      phoneNumber: '',
      phoneNumberOptional: null,
      designation: ''
    }
  ])
  const handleIndianState = (e, index, name) => {
    const value = e.label
    console.log(value)

    setAddress(prevState => {
      const updatedAddresses = [...prevState] // Copy the existing array
      updatedAddresses[index] = {
        // Update the address at the given index
        ...updatedAddresses[index],
        [name]: value // Update the specific field of the address
      }
      return updatedAddresses
    })
  }
  const handleAddressInput = (e, index) => {
    const { name, value } = e.target
    setAddress(prevState => {
      const updatedAddresses = [...prevState] // Copy the existing array
      updatedAddresses[index] = {
        // Update the address at the given index
        ...updatedAddresses[index],
        [name]: value // Update the specific field of the address
      }
      return updatedAddresses
    })
  }

  const handleContactInput = (e, index) => {
    const { name, value } = e.target
    setContactPersons(prevState => {
      const updatedContactPersons = [...prevState] // Create a copy of the current contactPersons array
      updatedContactPersons[index] = {
        // Update the contact person at the specified index
        ...updatedContactPersons[index],
        [name]: value
      }
      return updatedContactPersons // Return the updated array
    })
  }

  const handleCloseModal = () => {
    if (showSuccessModal === true) {
      setShowSuccessModal(false)
      navigate('/client_contact')
    }
    setShowDeclineModal(false)
  }

  const handleAddAddress = () => {
    setAddress([
      ...address,
      {
        addressNickName: '',
        addressLine1: '',
        addressLine2: null,
        city: '',
        state: '',
        country: 'India',
        pincode: ''
      }
    ])
  }

  const handleDeleteAddress = index => {
    setAddress(address.filter((_, idx) => idx !== index))
  }

  const handleAddContactPerson = () => {
    setContactPersons([
      ...contactPersons,
      {
        name: '',
        email: '',
        phoneNumber: '',
        phoneNumberOptional: null,
        designation: ''
      }
    ])
  }

  const handleDeleteContactPerson = index => {
    setContactPersons(contactPersons.filter((_, idx) => idx !== index))
  }

  const updateLeadForClient = async clientId => {
    try {
      const res = await fetch(`${API_URL}leads/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          client: clientId
        })
      })
      const response = await res.json()
      if (response.error === false) {
        navigate('/view_leads')
      } else {
        console.log('lead not updated')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (gstNumber === '') {
      setGstNumber(null)
    }
    if (companyName === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter The Client Name',
        life: 3000
      })
    } else if (/^(?:[6-9]\d{9})$/.test(phoneNumber) === false) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid Phone Number',
        life: 3000
      })
    } else if (
      phoneNumberOptional !== null &&
      /^(?:[6-9]\d{9})?$/.test(phoneNumberOptional) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid Alternate Phone Number',
        life: 3000
      })
    } else if (organizationType === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select The Organization Type',
        life: 3000
      })
    } else if (
      isGSTRegistered === 'true' &&
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        gstNumber
      ) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid GSTIN',
        life: 3000
      })
    } else if (
      isGSTRegistered === 'false' &&
      /^[A-Z]{5}[0-9]{4}[A-Z]$/.test(panNumber) === false
    ) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter a Valid PAN',
        life: 3000
      })
    } else if (industry === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select Industry',
        life: 3000
      })
    } else {
      try {
        const response = await fetch(`${API_URL}clients`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            companyName,
            phoneNumber,
            phoneNumberOptional,
            organizationType,
            isGSTRegistered,
            gstNumber,
            panNumber,
            industry,
            address,
            contactPersons
          })
        })
        const result = await response.json()
        if (result.error === false) {
          setShowSuccessModal(true)
          if (assignToLead === true) {
            updateLeadForClient(result.data.id)
          }
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: `${result.message.split('.')[1].replace('"', '')}`,
            life: 3000
          })
        }
      } catch (error) {
        setShowDeclineModal(true)
      }
    }
  }

  return (
    <Fragment>
      <div>
        <form className='row g-3 needs-validation' noValidate>
          <div className='row mt-3 pb-3 border-bottom'>
            <div className='col-md-4'>
              <label htmlFor='name' className='form-label'>
                Company Name <span className='text-danger'>*</span>
              </label>
              <input
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
                type='text'
                className='form-control'
                id='name'
                required
              />
              <div className='invalid-feedback'>
                Please enter the company name
              </div>
            </div>
            <div className='col-md-4'>
              <label htmlFor='name' className='form-label'>
                Phone <span className='text-danger'>*</span>
              </label>
              <input
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                type='number'
                className='form-control'
                id='name'
                required
              />
              <div className='invalid-feedback'>
                Please enter a valid phone number
              </div>
            </div>
            <div className='col-md-4'>
              <label htmlFor='name' className='form-label'>
                Alternate Phone
              </label>
              <input
                value={phoneNumberOptional}
                onChange={e => setPhoneNumberOptional(e.target.value)}
                type='number'
                className='form-control'
                id='name'
              />
            </div>
            <div className='col-md-3 mt-2'>
              <label htmlFor='name' className='form-label'>
                Organization Type <span className='text-danger'>*</span>
              </label>
              <select
                value={organizationType}
                onChange={e => setOrganizationType(e.target.value)}
                className='form-select custom-select'
              >
                <option>Select</option>
                <option>Public Limited</option>
                <option>Private Limited</option>
                <option>LLP</option>
                <option>Govt</option>
                <option>Public Sector Unit</option>
                <option>UT</option>
                <option>Partnership</option>
                <option>Proprietorship</option>
              </select>
            </div>
            <div className='col-md-3 mt-2'>
              <label htmlFor='name' className='form-label'>
                Is GST Registered <span className='text-danger'>*</span>
              </label>
              <select
                value={isGSTRegistered}
                onChange={e => setIsGSTRegistered(e.target.value)}
                className='form-select'
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </div>
            <div className='col-md-3 mt-2'>
              {isGSTRegistered === 'true' ? (
                <Fragment>
                  <label htmlFor='name' className='form-label'>
                    GSTIN
                    <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={gstNumber}
                    onChange={e => setGstNumber(e.target.value.toUpperCase())}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <label htmlFor='name' className='form-label'>
                    PAN
                    <span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    value={panNumber}
                    onChange={e => setPanNumber(e.target.value.toUpperCase())}
                  />
                </Fragment>
              )}
            </div>
            <div className='col-md-3 mt-2'>
              <label for='name' className='form-label'>
                Industy <span className='text-danger'>*</span>
              </label>
              <select
                name='industry'
                required
                value={industry}
                onChange={e => setIndustry(e.target.value)}
                data-section='productRequirements'
                className='form-select'
              >
                {IndustryList.map((data, index) => {
                  return <option key={index}>{data}</option>
                })}
              </select>
            </div>
          </div>
          {address.map((add, index) => (
            <div className='row mt-3' key={index}>
              <div className='d-flex justify-content-between'>
                <span>
                  <h6>
                    Add{' '}
                    {(countAddress = countAddress + 1) === 1
                      ? 'First'
                      : 'Second'}{' '}
                    Address
                  </h6>
                </span>
                {countAddress > 1 ? (
                  <span>
                    <div>
                      <Link className='text-dark no-underline'>
                        <FontAwesomeIcon
                          className='ml-1 text-danger'
                          onClick={() => handleDeleteAddress(index)}
                          icon={faTrashCan}
                        />
                      </Link>
                    </div>
                  </span>
                ) : (
                  ' '
                )}
              </div>

              <div className='col-md-4'>
                <label className='form-label'>
                  Country <span className='text-danger'>*</span>
                </label>
                <select
                  name='country'
                  value={address.country}
                  onChange={e => handleAddressInput(e, index)}
                  className='form-select'
                >
                  <option value='India'>India</option>
                  <option value='Bangladesh'>Bangladesh</option>
                </select>
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <label className='form-label'>
                  Address Nick Name <span className='text-danger'>*</span>
                </label>
                <input
                  name='addressNickName'
                  value={address.addressNickName}
                  onChange={e => handleAddressInput(e, index)}
                  type='text'
                  className='form-control'
                  placeholder='Eg: Head Office'
                />
              </div>
              <div className='col-md-4'>
                <label className='form-label'>
                  Address Line 1 <span className='text-danger'>*</span>
                </label>
                <input
                  name='addressLine1'
                  type='text'
                  className='form-control'
                  value={address.addressLine1}
                  onChange={e => handleAddressInput(e, index)}
                />
              </div>
              <div className='col-md-4'>
                <label className='form-label'>Address Line 2</label>
                <input
                  name='addressLine2'
                  type='text'
                  className='form-control'
                  value={address.addressLine2}
                  onChange={e => handleAddressInput(e, index)}
                />
              </div>

              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  City <span className='text-danger'>*</span>
                </label>
                <input
                  name='city'
                  type='text'
                  className='form-control'
                  value={address.city}
                  onChange={e => handleAddressInput(e, index)}
                />
              </div>
              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  State <span className='text-danger'>*</span>
                </label>

                {address[index].country === 'India' ? (
                  <Select
                    name='state'
                    value={IndianStates.find(
                      option => option.value === address.state
                    )}
                    onChange={e => handleIndianState(e, index, 'state')}
                    options={IndianStates}
                  />
                ) : (
                  <input
                    name='state'
                    type='text'
                    className='form-control'
                    value={address.state}
                    onChange={e => handleAddressInput(e, index)}
                  />
                )}
              </div>
              <div className='col-md-4 mt-2'>
                <label className='form-label'>
                  Pin: <span className='text-danger'>*</span>
                </label>
                <input
                  name='pincode'
                  type='number'
                  className='form-control'
                  value={address.pincode}
                  onChange={e => handleAddressInput(e, index)}
                />
              </div>
            </div>
          ))}
          <div className='row mt-3'>
            <div className='col-md-4'>
              {countAddress <= 1 ? (
                <button
                  onClick={handleAddAddress}
                  type='button'
                  id='add_address'
                  className='btn add-btn  text-white'
                >
                  Add New Address
                </button>
              ) : (
                ' '
              )}
            </div>
          </div>
          {contactPersons.map((person, index) => (
            <div className='row mt-3' key={index}>
              <div>
                <span className='d-flex justify-content-between'>
                  <h6>
                    Add{' '}
                    {(() => {
                      const ordinals = [
                        'First',
                        'Second',
                        'Third',
                        'Fourth',
                        'Fifth'
                      ]
                      return (
                        ordinals[
                          (countContactPerson = countContactPerson + 1) - 1
                        ] || countAddress
                      )
                    })()}{' '}
                    Contact Person
                  </h6>
                  {countContactPerson > 1 ? (
                    <span>
                      <div>
                        <Link className='text-dark no-underline'>
                          <FontAwesomeIcon
                            className='ml-1 text-danger'
                            onClick={() => handleDeleteContactPerson(index)}
                            icon={faTrashCan}
                          />
                        </Link>
                      </div>
                    </span>
                  ) : (
                    ' '
                  )}
                </span>
              </div>

              <div className='col-md-6'>
                <label className='form-label'>
                  Name <span className='text-danger'>*</span>
                </label>
                <input
                  name='name'
                  type='text'
                  className='form-control'
                  value={contactPersons.name}
                  onChange={e => handleContactInput(e, index)}
                />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  name='email'
                  type='email'
                  className='form-control'
                  value={contactPersons.email}
                  onChange={e => handleContactInput(e, index)}
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <input
                  name='phoneNumber'
                  type='number'
                  className='form-control'
                  value={contactPersons.phoneNumber}
                  onChange={e => handleContactInput(e, index)}
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>Alternate Phone</label>
                <input
                  name='phoneNumberOptional'
                  type='number'
                  className='form-control'
                  value={contactPersons.phoneNumberOptional}
                  onChange={e => handleContactInput(e, index)}
                />
              </div>
              <div className='col-md-4 mt-3'>
                <label className='form-label'>
                  Designation <span className='text-danger'>*</span>
                </label>
                <input
                  name='designation'
                  type='text'
                  className='form-control'
                  value={contactPersons.designation}
                  onChange={e => handleContactInput(e, index)}
                />
              </div>
            </div>
          ))}
          <div className='row mt-3'>
            <div className='col-md-4'>
              {countContactPerson < 5 ? (
                <button
                  type='button'
                  onClick={handleAddContactPerson}
                  className='btn add-btn text-white'
                >
                  Add New Contact
                </button>
              ) : (
                ' '
              )}
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-12'>
              <button
                className='btn add-btn text-white'
                onClick={handleSubmit}
                type='button'
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <Toast ref={toast} />
      </div>

      <Modal show={showSuccessModal} centered>
        <Modal.Body>
          <div class='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              class='w-14 shrink-0 fill-green-500 inline'
              viewBox='0 0 512 512'
            >
              <path
                d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                data-original='#000000'
              />
              <path
                d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                data-original='#000000'
              />
            </svg>
            <h4 class='text-xl text-gray-800 font-semibold mt-4'>Good Job</h4>
            <p class='text-sm text-gray-500 leading-relaxed mt-4'>
              Client Added Succesfully!
            </p>
          </div>

          <button
            type='button'
            onClick={handleCloseModal}
            class='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showDeclineModal} centered>
        <Modal.Body>
          <div class='my-8 text-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              class='w-14 fill-red-500 inline'
              viewBox='0 0 286.054 286.054'
            >
              <path
                fill='#e2574c'
                d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                data-original='#e2574c'
              ></path>
            </svg>
            <h4 class='text-xl text-gray-800 font-semibold mt-4'>
              Error Occured
            </h4>
            <p class='text-sm text-gray-500 leading-relaxed mt-4'>
              Something went wrong!
            </p>
          </div>

          <button
            type='button'
            onClick={handleCloseModal}
            class='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
          >
            Okay
          </button>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

export default AddClient
