import React, { Fragment, useEffect, useState } from 'react'
import PageHeader from './utils/PageHeader'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import { CardBody, Col, Row, Table } from 'reactstrap'
import { API_URL } from './utils/Constants/URLS'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
const ViewExpense = () => {
  const token = localStorage.getItem('token')
  const [expenseData, setExpenseData] = useState([])
  const [userData, setUserData] = useState([])
  const [leadData, setLeadData] = useState([])
  const [businessLineData, setBusinessLineData] = useState([])
  const [businessLine, setBusinessLine] = useState('')
  const [leadOwner, setLeadOwner] = useState('')
  const [leadId, setLeadId] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [openExpenseDetailsModal, setOpenExpenseDetailsModal] = useState(false)
  const [expenseDetails, setExpenseDetails] = useState('')
  const [reasonForRejection, setReasonForRejection] = useState('')
  const [expenseId, setExpenseId] = useState('')
  const [status, setStatus] = useState('')
  const handleRejectReq = (id, sts) => {
    setOpenModal(true)
    setExpenseId(id)
    setStatus(sts)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setShowSuccessModal(false)
    setExpenseId('')
    setStatus('')
    window.location.reload()
  }

  const handleExpenseDetailsModal = expenseDetails => {
    setOpenExpenseDetailsModal(true)
    setExpenseDetails(expenseDetails)
  }
  const approveExp = async (e, id, sts) => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}expenses/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          status: sts
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setShowSuccessModal(true)
        setModalMessage('The Expense is Approved')
      }
    } catch (e) {
      console.log(e)
    }
  }
  const rejectExp = async e => {
    e.preventDefault()
    try {
      const res = await fetch(`${API_URL}expenses/${expenseId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          status,
          reasonForRejection
        })
      })
      const result = await res.json()
      if (result.error === false) {
        setShowSuccessModal(true)
        setModalMessage('The Expense is Rejected')
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const res = await fetch(
          `${API_URL}expenses?pagination=false${
            leadOwner === '' ? '' : '&createdBy=' + leadOwner
          }${leadId === '' ? '' : '&leadId=' + leadId}${
            businessLine === '' ? '' : '&businessLine=' + businessLine
          }&status=pending`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        setExpenseData(result?.data?.docs)
      } catch (e) {
        console.log(e)
      }
    }
    fetchExpenses()
  }, [token, leadOwner, leadId, businessLine])

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const res = await fetch(`${API_URL}leads?pagination=false`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setLeadData(
          result?.data?.docs.map(item => ({
            value: item?.id,
            label: item?.leadJourneyName
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    fetchLeads()
  }, [token])

  useEffect(() => {
    const fetchLeadOwner = async () => {
      try {
        const res = await fetch(
          `${API_URL}users/org-contacts?pagination=false&search=executive`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        setUserData(
          result?.data?.docs.map(item => ({
            value: item?.id,
            label: item?.name
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    fetchLeadOwner()
  }, [token])

  useEffect(() => {
    const fetchBusinessLines = async () => {
      try {
        const res = await fetch(`${API_URL}business-lines?pagination=false`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        const result = await res.json()
        setBusinessLineData(
          result?.data?.docs.map(item => ({
            value: item?.id,
            label: item?.name
          }))
        )
      } catch (e) {
        console.log(e)
      }
    }
    fetchBusinessLines()
  }, [token])

  const downloadReceit = async docs_id => {
    try {
      const res = await fetch(`${API_URL}expenses/${docs_id}/download`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        redirect: 'follow'
      })
      const blob = await res.blob()
      const pdfUrl = window.URL.createObjectURL(blob)
      // const a = document.createElement("a");
      // a.href = pdfUrl;
      // a.download = `receipt_${docs_id}.pdf`;
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);
      // window.URL.revokeObjectURL(pdfUrl);
      // Open PDF in a new tab
      window.open(pdfUrl, '_blank')

      // Cleanup the URL after some time to avoid memory leaks
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfUrl)
      }, 5000)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Fragment>
      <PageHeader
        pageName='VIEW EXPENSE'
        breadcrump1='Home'
        breadcrump2='View Expense'
        link='/dashboard'
      />
      <div className='main lg:p-6 xl:p-6 2xl:p-6 sm:p-3 md:p-3 '>
        <CardBody className='bg-white p-3'>
          <form>
            <Row>
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className='mb-2'>
                  <Select
                    options={leadData}
                    onChange={e => setLeadId(e.value)}
                    placeholder='Select Lead'
                  />
                </div>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className='mb-2'>
                  <Select
                    options={userData}
                    onChange={e => setLeadOwner(e.value)}
                    placeholder='Select Lead Owner'
                  />
                </div>
              </Col>
              <Col lg={3} md={3} sm={12} xs={12}>
                <div className='mb-2'>
                  <Select
                    options={businessLineData}
                    onChange={e => setBusinessLine(e.value)}
                    placeholder='Select Business Line'
                  />
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
        <CardBody>
          <div className='table-responsive sm:overflow-auto sm:max-h-[400px] w-full  mb-3 '>
            <Table hover className='align-middle table-nowrap'>
              <thead className='table-light'>
                <tr>
                  <th>Lead Name</th>
                  <th>Business Line</th>
                  <th>Executive</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Receipt</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {expenseData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data?.leadId?.leadJourneyName}</td>
                      <td>{data?.businessLine?.name}</td>
                      <td>{data?.createdBy?.name}</td>
                      <td>{data?.expenseType}</td>
                      <td>{data?.expenseDate.slice(0, 10)}</td>
                      <td>{data?.expenseAmount}</td>
                      {data?.isReceiptAvailable === true ? (
                        <td onClick={() => downloadReceit(data?.id)}>
                          <FontAwesomeIcon icon={faEye} />
                        </td>
                      ) : (
                        <td
                          onClick={() =>
                            handleExpenseDetailsModal(data?.expenseDescription)
                          }
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </td>
                      )}

                      {data?.status === 'pending' ? (
                        <td>
                          <button
                            className='btn add-btn text-white'
                            type='button'
                            onClick={e => approveExp(e, data?.id, 'approved')}
                          >
                            Approve
                          </button>
                          <button
                            className='btn btn-danger text-white ml-2'
                            type='button'
                            onClick={() =>
                              handleRejectReq(data?.id, 'rejected')
                            }
                          >
                            Decline
                          </button>
                        </td>
                      ) : (
                        <td>
                          <span
                            className={
                              data?.status === 'approved'
                                ? 'text-green-500'
                                : 'text-red-500'
                            }
                          >
                            <strong>{data?.status.toUpperCase()}</strong>
                          </span>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
        {/* <CardBody className='float-right'>
            <nav className='ml-5' aria-label='...'>
              <ul className='pagination'>
                <li className='page-item disabled'>
                  <Link className='page-link text-primary' to='#' tabIndex='-1'>
                    Previous
                  </Link>
                </li>
                {[...Array(totalPages)].map((_, index) => {
                  return (
                    <li className='page-item' key={index}>
                      <Link
                        onClick={() => setPageNumber(index + 1)}
                        className='page-link text-primary'
                        to='#'
                      >
                        {index + 1}
                      </Link>
                    </li>
                  )
                })}
                <li className='page-item'>
                  <Link className='page-link text-primary' to='#'>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </CardBody> */}

        <Modal show={openModal} onHide={handleCloseModal} centered>
          <Modal.Header className='bg-red-100' closeButton>
            <Modal.Title>Reject Expense Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div class='my-8 text-center'>
                <textarea
                  className='form-control h-full'
                  value={reasonForRejection}
                  onChange={e => setReasonForRejection(e.target.value)}
                  placeholder='Enter The Reason to Reject The Request'
                />
              </div>

              <div class='flex max-sm:flex-col gap-4'>
                <button
                  onClick={handleCloseModal}
                  type='button'
                  class='px-5 py-2.5 rounded-lg w-full tracking-wide text-gray-800 text-sm border-none outline-none bg-gray-200 hover:bg-gray-300'
                >
                  Cancel
                </button>
                <button
                  type='button'
                  onClick={rejectExp}
                  class='px-5 py-2.5 rounded-lg w-full tracking-wide text-white text-sm border-none outline-none bg-red-500 hover:bg-red-600'
                >
                  Reject
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Job Done
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                {modalMessage}!
              </p>
            </div>

            <button
              type='button'
              onClick={handleCloseModal}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal
          show={openExpenseDetailsModal}
          centered
          onHide={() => setOpenExpenseDetailsModal(false)}
        >
          <Modal.Header className='bg-indigo-100' closeButton>
            <Modal.Title>Expense Description</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class='my-8 text-center'>
              <strong>{expenseDetails}</strong>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  )
}

export default ViewExpense
