import React, { Fragment, useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { API_URL } from '../utils/Constants/URLS'

const ViewPipeline = () => {
  const token = localStorage.getItem('token')
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${API_URL}analytics/categorize-leads-by-stage`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await res.json()
      if (result.error === false) {
        setData(result?.data)
      }
    }
    fetchData()
  }, [token])
  return (
    <Fragment>
      <Card>
        <CardHeader>Deals in Pipeline</CardHeader>
        <CardBody>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Pipeline Name</th>
                <th>No of Deals</th>
                <th>Total Deal Value</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dt, index) => {
                return (
                  <tr key={index}>
                    <td>{dt?.name}</td>
                    <td>{dt?.count}</td>
                    <td>{dt?.totalDealValue}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default ViewPipeline
