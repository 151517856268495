import React, { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { API_URL } from '../utils/Constants/URLS'
import { useNavigate } from 'react-router-dom'

const LeadJourneyName = props => {
  const { isOpen, clientId } = props
  const token = localStorage.getItem('token')
  const [warningMessage, setWarningMessage] = useState('')
  const [showModal, setShowModal] = useState(isOpen)
  const [leadJourneyName, setLeadJourneyName] = useState('')
  const navigate = useNavigate()
  const handleCreateLead = async e => {
    e.preventDefault()
    if (leadJourneyName.length > 50) {
      setWarningMessage('Please Enter a Name Within 50 Characters')
    } else {
      try {
        const url = `${API_URL}leads`
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            leadJourneyName,
            client: clientId
          })
        })

        const response = await res.json()
        if (response.error === false) {
          navigate('/view_leads')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    navigate(-1);
  }

  return (
    <Fragment>
      <div
        className='modal show'
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header className='bg-blue-100' closeButton>
            <Modal.Title>Create Lead</Modal.Title>
          </Modal.Header>
          <form
            className='row g-3 needs-validation'
            onSubmit={handleCreateLead}
            noValidate
          >
            <Modal.Body>
              <div>
                <div className='col-md-12'>
                  <label
                    htmlFor='validationCustomUsername'
                    className='form-label'
                  >
                    Lead Name <span className='text-danger'>*</span>
                  </label>
                  <div className='input-group has-validation'>
                    <input
                      value={leadJourneyName}
                      onChange={e => setLeadJourneyName(e.target.value)}
                      type='text'
                      className='form-control'
                      id={warningMessage!==''?'error':''}
                      aria-describedby='inputGroupPrepend'
                      autoComplete='off'
                      required
                    />
                    
                  </div>
                  <span className='text-red-600'>{warningMessage}</span>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button type='submit' className='btn add-btn'>
                Create
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </Fragment>
  )
}

export default LeadJourneyName
