import React, { Fragment, useEffect, useState, useRef } from 'react'
import PageHeader from './utils/PageHeader'
import Modal from 'react-bootstrap/Modal'
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Table,
  CardFooter
} from 'reactstrap'
import Select from 'react-select'
import { API_URL } from './utils/Constants/URLS'
import PageLoader from './utils/PageLoader'
import { Toast } from 'primereact/toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const AddExpense = () => {
  const token = localStorage.getItem('token')
  let countExpense = 0
  const [pageLoader, setPageLoader] = useState(false)
  const toast = useRef(null)
  const [leadData, setLeadData] = useState([{}])
  const [leadId, setLeadId] = useState('')
  const [expenseType, setExpenseType] = useState('')
  const [expenseAmount, setExpenseAmount] = useState('')
  const [expenseDate, setExpenseDate] = useState(null)
  const [expenseReceit, setExpenseReceit] = useState(null)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const [expenseData, setExpenseData] = useState([])
  const [isReceiptAvailable, setIsReceiptAvailable] = useState('true')
  const [expenseDescription, setExpenseDescription] = useState('')
  const [data, setData] = useState([
    {
      isReceiptAvailable: '',
      expenseDescription: ''
    }
  ])

  const handleAddExpense = async e => {
    e.preventDefault()
    if (leadId === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select the Lead',
        life: 3000
      })
    } else if (expenseType === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Select the Expense Type',
        life: 3000
      })
    } else if (expenseDate === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the expense date',
        life: 3000
      })
    } else if (expenseAmount === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter the Expense Amount',
        life: 3000
      })
    } else if (isReceiptAvailable === 'true' && expenseReceit === null) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Upload the Receit',
        life: 3000
      })
    } else if (isReceiptAvailable === 'false' && expenseDescription === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Enter The Expense Description',
        life: 3000
      })
    } else {
      setPageLoader(true)
      const formData = new FormData()
      formData.append('leadId', leadId)
      formData.append('expenseType', expenseType)
      formData.append('expenseAmount', expenseAmount)
      formData.append('expenseDate', expenseDate)
      formData.append('isReceiptAvailable', isReceiptAvailable)
      if (isReceiptAvailable === 'true') {
        formData.append('pdf', expenseReceit)
      } else {
        formData.append('expenseDescription', expenseDescription)
      }

      try {
        const res = await fetch(`${API_URL}/expenses`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: formData,
          redirect: 'follow'
        })
        const result = await res.json()
        if (result.error === false) {
          setShowSuccessModal(true)
        } else {
          setShowDeclineModal(true)
        }
      } catch (e) {
        setShowDeclineModal(true)
      } finally {
        setPageLoader(false)
      }
    }
  }

  const getMinDate = () => {
    const today = new Date()
    today.setDate(today.getDate() - 2) // Subtract 2 days
    return today.toISOString().split('T')[0] // Format to YYYY-MM-DD
  }

  const getMaxDate = () => {
    const today = new Date()
    return today.toISOString().split('T')[0] // Format to YYYY-MM-DD
  }

  useEffect(() => {
    const fetchLead = async () => {
      const res = await fetch(`${API_URL}leads?pagination=false`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await res.json()
      setLeadData(
        result?.data?.docs.map(item => ({
          value: item?.id,
          label: item?.leadJourneyName
        }))
      )
    }
    fetchLead()
  }, [token])

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const res = await fetch(
          `${API_URL}expenses?pagination=false&status=pending,rejected`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        const result = await res.json()
        setExpenseData(result?.data?.docs)
      } catch (e) {
        console.log(e)
      }
    }
    fetchExpenses()
  }, [token])

  const handleAddMoreExp = () => {
    setData([
      ...data,
      {
        isReceiptAvailable: '',
        expenseDescription: ''
      }
    ])
  }

  const handleDelMoreExp = index => {
    setData(data.filter((_, idx) => idx !== index))
  }

  return (
    <Fragment>
      <PageHeader
        pageName='CLIENT PROFILE'
        breadcrump1='Home'
        breadcrump2='Client Profile'
        link='/dashboard'
      />
      <div className='p-3'>
        <Card>
          {pageLoader && <PageLoader />}
          <CardHeader>Add Expenses</CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <div className='mt-3'>
                  <span>
                    <h6>Select Date and Lead</h6>
                    <label>Expense Date</label>
                    <input
                      type='date'
                      onClick={e =>
                        e.target.showPicker && e.target.showPicker()
                      }
                      min={getMinDate()}
                      max={getMaxDate()}
                      value={expenseDate}
                      onChange={e => setExpenseDate(e.target.value)}
                      className='form-control'
                    />
                    <label>Select Lead</label>
                    <Select
                      options={leadData}
                      onChange={e => setLeadId(e.value)}
                    />
                  </span>
                </div>
              </Col>
              <Col md={8}>
                <Row>
                  {data.map((dt, index) => {
                    return (
                      <div key={index}>
                        <div className='mt-3'>
                          <span className='d-flex justify-content-between'>
                            <h6>
                              Add{' '}
                              {(() => {
                                const ordinals = [
                                  'First',
                                  'Second',
                                  'Third',
                                  'Fourth'
                                ]
                                return ordinals[
                                  (countExpense = countExpense + 1) - 1
                                ]
                              })()}{' '}
                              Expense
                            </h6>
                            {countExpense > 1 ? (
                              <span>
                                <div>
                                  <Link
                                    onClick={() => handleDelMoreExp(index)}
                                    className='text-dark no-underline'
                                  >
                                    <FontAwesomeIcon
                                      className='ml-1 text-danger'
                                      icon={faTrashCan}
                                    />
                                  </Link>
                                </div>
                              </span>
                            ) : (
                              ' '
                            )}
                          </span>
                        </div>
                        <Row>
                          <Col md={4}>
                            <label>Expense Type</label>
                            <select
                              className='form-select'
                             
                            >
                              <option>Select</option>
                              <option>Travel</option>
                              <option>Food</option>
                              <option>Acomodation</option>
                              <option>Others</option>
                            </select>
                          </Col>

                          <Col md={4} className='mt-2'>
                            <label>Expense Amount</label>
                            <input
                             
                              type='number'
                              className='form-control'
                            />
                          </Col>
                          <Col md={4} className='mt-1'>
                            <label>Expense Receipt</label>
                            <input
                              
                              type='file'
                              accept='application/pdf'
                              className='form-control'
                            />
                          </Col>
                          <Col md={12} className='mt-2'>
                            <label>Expense Description</label>
                            <textarea
                             
                              type='text'
                              className='form-control'
                            />
                          </Col>
                        </Row>
                      </div>
                    )
                  })}
                  {countExpense <= 3 ? (
                    <div>
                      <Row>
                        <Col md={4}>
                          <button
                            onClick={handleAddMoreExp}
                            className='btn add-btn text-white mt-3'
                          >
                            Add More
                          </button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ''
                  )}
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <button
              onClick={handleAddExpense}
              className='btn add-btn text-white float-right mt-3'
            >
              Add
            </button>
          </CardFooter>
        </Card>
        <CardBody className='mt-2'>
          <div className='table-responsive sm:overflow-auto sm:max-h-[400px] w-full  mb-3 '>
            <Table hover className='align-middle table-nowrap'>
              <thead className='table-light'>
                <tr>
                  <th>Lead Name</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {expenseData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data?.leadId?.leadJourneyName}</td>
                      <td>{parseFloat(data?.expenseAmount)}</td>
                      <td>{data?.expenseType}</td>
                      <td>{data?.expenseDate.slice(0, 10)}</td>
                      <td>
                        <span
                          className={
                            data?.status === 'pending'
                              ? 'text-blue-500'
                              : 'text-red-500'
                          }
                        >
                          {data?.status === 'rejected'
                            ? (data?.status).toUpperCase() +
                              `: ${data?.reasonForRejection}`
                            : (data?.status).toUpperCase()}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>

        <Toast ref={toast} />
        <Modal show={showSuccessModal} centered>
          <Modal.Body>
            <div class='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 shrink-0 fill-green-500 inline'
                viewBox='0 0 512 512'
              >
                <path
                  d='M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z'
                  data-original='#000000'
                />
                <path
                  d='M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z'
                  data-original='#000000'
                />
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Success
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                Expense Created Successfully Please Wait for Approval!
              </p>
            </div>

            <button
              type='button'
              onClick={() => {
                setShowSuccessModal(false)
              }}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-green-500 hover:bg-green-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
        <Modal show={showDeclineModal} centered>
          <Modal.Body>
            <div className='my-8 text-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-14 fill-red-500 inline'
                viewBox='0 0 286.054 286.054'
              >
                <path
                  fill='#e2574c'
                  d='M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.209-52.026-116.209-116.209S78.844 26.818 143.027 26.818s116.209 52.026 116.209 116.209-52.026 116.209-116.209 116.209zm.009-196.51c-10.244 0-17.995 5.346-17.995 13.981v79.201c0 8.644 7.75 13.972 17.995 13.972 9.994 0 17.995-5.551 17.995-13.972V76.707c-.001-8.43-8.001-13.981-17.995-13.981zm0 124.997c-9.842 0-17.852 8.01-17.852 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.001-9.851-8.001-17.86-17.843-17.86z'
                  data-original='#e2574c'
                ></path>
              </svg>
              <h4 className='text-xl text-gray-800 font-semibold mt-4'>
                Sorry!
              </h4>
              <p className='text-sm text-gray-500 leading-relaxed mt-4'>
                Something Went Wrong
              </p>
            </div>

            <button
              type='button'
              onClick={() => setShowDeclineModal(false)}
              className='px-5 py-2.5 w-full rounded-lg text-white text-sm border-none outline-none bg-red-500 hover:bg-red-700'
            >
              Okay
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  )
}

export default AddExpense
