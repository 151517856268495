import React, { Fragment, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { API_URL } from '../utils/Constants/URLS'

const PipeLineByStage = () => {
  const token = localStorage.getItem('token')
  const [data, setData]=useState([])
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${API_URL}analytics/pipeline-stage-count`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/Json',
          Authorization: `Bearer ${token}`
        }
      })
      const result = await res.json()
      const formattedData = [
        {
          x: 'Product Requirements',
          y: result?.data[0]?.productRequirements[0]?.count || 0
        },
        {
          x: 'Enquiry Details',
          y: result?.data[0]?.enquiryDetails[0]?.count || 0
        },
        {
          x: 'Marketing Platform',
          y: result?.data[0]?.marketingPlatform[0]?.count || 0
        },
        {
          x: 'Working Platform',
          y: result?.data[0]?.workingPlatform[0]?.count || 0
        },
        {
          x: 'Buying Platform',
          y: result?.data[0]?.buyingPlatform[0]?.count || 0
        },
        { x: 'Agreement', y: result?.data[0]?.agreement[0]?.count || 0 }
      ]
      setData(formattedData)
    }
    fetchData()
  },  [token])
  const series = [
    {
      name: 'Funnel Series',
      data: data.map(item=>item?.y)
    }
  ]
  const options = {
    chart: {
      type: 'bar',
      height: 350
    },
    colors: ['#2E93fA'],
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: '80%',
        isFunnel: true
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
      },
      dropShadow: {
        enabled: true
      }
    },
    xaxis: {
      categories: data.map(item=>item?.x)
    },
    legend: {
      show: false
    }
  }
  return (
    <Fragment>
      <Card>
        <CardHeader>Pipe Line By Stage</CardHeader>
        <CardBody>
          <ReactApexChart
            dir='ltr'
            className='apex-charts'
            series={series}
            options={options}
            type='bar'
            height={350}
          />
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default PipeLineByStage
